<template>
    <div class="audit-container">
        <div class="top-wrap">
            <div class="job-search-cell">
                <div class="item-label">职位</div>
                <el-input
                    @change="handlesearchJobChange"
                    @clear="handlesearchJobChange"
                    class="job-search-input"
                    clearable
                    placeholder="搜索职位名称（按回车搜索）"
                    v-model="searchJob"
                ></el-input>
            </div>
        </div>
        <div class="main">
            <job-list
                :jobList="newJobList"
                :listLoading="loading"
                :params="params"
                @refresh="getJobList"
                ref="publicJobList"
            ></job-list>
        </div>
    </div>
</template>

<script>
import JobList from './layout/job-list.vue';
import PublicJobService from '#/js/service/publicJobService.js';

export default {
    name: 'AuditOpportunity',
    components: {
        JobList,
    },
    data () {
        return {
            searchJob: '',

            jobIds: [], // 当前列表的职位id集合
            jobStatisticInfoList: [],
            newJobList: [],
            jobList: [],
            loading: false,
            params: {},
        };
    },
    created () {
    },
    mounted () {
    },
    methods: {
        handlesearchJobChange () {
            this.$refs.publicJobList.search(true);
        },
        getJobList (params, slient) {
            this.loading = true;
            this.jobList = [];
            // console.log(`params:`, params);
            // params.status = params.status.split('');
            params = {
                ...params,
                status: +params.status,
                jobName: this.searchJob,
            };

            PublicJobService.getPublicJobAuditList(params)
                .then(res => {
                    this.loading = false;
                    this.jobList = res.list;
                    this.$set(this.$refs.publicJobList.pager, 'total', res.total);
                    // this.$nextTick(() => {
                    //     if (slient) {
                    //         document.querySelector('.caudit-container').scrollTop = document.querySelector('.public-job-content').offsetTop;
                    //     } else if (slient !== null) {
                    //         document.querySelector('.audit-container').scrollTop = document.querySelector('.public-job-search').offsetTop;
                    //     }
                    // });
                    // this.newJobList = this.jobList;

                    // 每次查找之后都把jobIds置空
                    this.jobIds = [];
                    this.jobList.forEach( el => {
                        this.jobIds.push(el.id);
                    })
                    this.getPublicJobStatistics();
                }).catch(err => {
                    this.loading = false;
                    console.log(err);
                });
            this.params = params;
        },
        // 获取大厂职位统计数（投递、收藏、面试数）
        getPublicJobStatistics() {
            let params = {
                PublicJobIds: this.jobIds
            };
            PublicJobService.getPublicJobStatistics(params)
                .then(res => {
                    // console.log(res);
                    this.jobStatisticInfoList = res;

                    if(this.jobStatisticInfoList.length > 0) {
                        this.jobList.forEach(item => {
                            this.jobStatisticInfoList.forEach(el => {
                                if(item.id.indexOf(el.publicJobId) != -1) {
                                    item.jobStatisticInfo = el;
                                }
                            })
                        })
                        this.newJobList = this.jobList;
                    } else {
                        this.newJobList = this.jobList;
                    }
                    // console.log(this.newJobList);
                }).catch(err => {
                    this.loading = false;
                    console.log(err);
                });
        },
    },
}
</script>

<style lang="scss" scope>
.audit-container {
    height: 100%;
    overflow-y: auto;
    padding: 20px 20px 30px;
    // display: flex;
    // justify-content: space-between;
    div {
        box-sizing: border-box;
    }

    .top-wrap {
        background: white;
        border-radius: 8px;

        padding: 20px;
        margin-bottom: 20px;
        .job-search-cell {
            width: 100%;
            height: 42px;
            background: #ffffff;
            // border: 1px solid #d3d3d3;
            border-radius: 4px;
            display: flex;
            position: relative;
            .item-label {
                // width: 69px;
                // height: 40px;
                height: 38px;
                width: 80px;
                text-align: center;
                display: inline-block;
                position: absolute;
                z-index: 1;
                top: 1px;
                left: 1px;
                white-space: nowrap;
                background: #fff;
                line-height: 40px;
                font-size: 14px;
                color: #333;
                border-radius: 4px;
                &::after {
                    content: '';
                    display: inline-block;
                    width: 1px;
                    height: 24px;
                    background: #ddd;
                    position: absolute;
                    right: 0;
                    top: 9px;
                }
            }
            .job-search-input {
                width: 100%;
                height: 42px;
                .el-input__inner {
                    padding-left: 92px;
                }
            }
        }
    }

    .main {
        background: white;
        border-radius: 8px;
        // .status-bar {
        //     height: 60px;
        //     box-sizing: border-box;
        //     border-bottom: 1px solid #eee;
        // }
    }
}
</style>