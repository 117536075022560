<template>
    <div class="public-job-search">
        <div class="job-search-bottom">
            <!-- <div class="search-param-list">
                您的搜索结果数：
                <el-tag
                    closable
                    effect="plain"
                    class="search-param-item"
                    v-for="(search, index) in searchConditionList"
                    :key="index"
                    @close="removeLabel(search)"
                    v-show="search.value">
                    {{search.text}}: {{search.value}}
                </el-tag>
                <span class="job-total">
                    共 <span class="text-primary">{{jobNumber | currency('', 0)}}</span> 个职位
                </span>
                <span class="clear-search text-primary" @click="handleClearSearch">
                    <i class="el-icon-delete"></i>
                    清空搜索条件
                </span>
            </div> -->
            <div class="job-search-select">
                <span class="job-search-dropdown-title">状态：</span>
                <el-dropdown
                    trigger="click"
                    class="job-search-dropdown"
                    @command="(val) => handleJobDropdown(val, 'status')"
                    @visible-change="(val) => handleDropdownVisible(val, 'status')">
                    <span class="el-dropdown-link">
                        {{statusText}}
                        <i
                            class="el-icon--right"
                            :class="{
                                'el-icon-arrow-down': !statusDropdownVisible,
                                'el-icon-arrow-up': statusDropdownVisible
                            }"
                        ></i>
                    </span>
                    <el-dropdown-menu
                        slot="dropdown"
                        class="job-search-dropdown-menu">
                        <el-dropdown-item
                            v-for="status in jobStatusList"
                            :key="status.value"
                            :command="status.value"
                            :class="{'active': jobSearchForm.status == status.value}">
                            {{status.label}}
                        </el-dropdown-item>
                    </el-dropdown-menu>
                </el-dropdown>
            </div>
        </div>
    </div>
</template>

<script>
import moment from 'moment'

const defaultSearchLabels = {
    createDate: {
        name: "createDate",
        title: "创建时间",
        value: ""
    },
};

export default {
    props: {
        jobNumber: {
            type: Number,
            default: 0
        },
    },
    components: {

    },
    data() {
        return {
            statusDropdownVisible: false,
            // 职位状态
            jobStatusList: [
                {
                    value: '3',
                    label: '待审核'
                },
                {
                    value: '9',
                    label: '审核通过'
                },
                {
                    value: '5',
                    label: '未通过'
                },
                // {
                //     value: '',
                //     label: '全部'
                // },
                // {
                //     value: '0',
                //     label: '编辑中'
                // },
                // {
                //     value: '3',
                //     label: '审核中'
                // },
                // {
                //     value: '5',
                //     label: '审核未通过'
                // },
                // {
                //     value: '7',
                //     label: '未发布'
                // },
                // {
                //     value: '9',
                //     label: '发布中'
                // },
                // {
                //     value: '11',
                //     label: '源职位已暂停'
                // },
                // {
                //     value: '13',
                //     label: '源职位已完成'
                // },
            ],
            jobSearchForm: {
                status: "3", 
                // startDate: "",
                // endDate: "",
            },
            searchLabels: JSON.parse(JSON.stringify(defaultSearchLabels)),
            // searchConditionList: [] // 查询的tag列表
        }
    },
    computed: {
        statusText() {
            let jobStatusSelected = this.jobStatusList.find(item => item.value === this.jobSearchForm.status);
            return jobStatusSelected?.label || '全部';
        },
    },
    mounted() {
        
    },
    methods: {
        addParams() {
            let searchList = this.jobSearchForm,
                searchLabelList = this.searchLabels;
            // console.log(searchList);
            // console.log(searchLabelList);
            Object.keys(searchList).forEach(item => {
                switch(item) {
                    case 'start':
                    case 'take':
                    case 'status':
                    case 'endDate':
                        break;
                    case 'startDate':
                        searchLabelList.createDate.value = searchList.startDate ? searchList.startDate + '至' + searchList.endDate : "";
                        break;
                    default:
                        console.log(searchLabelList[item])
                        searchLabelList[item].value = searchList[item];
                }
            })
            this.$emit('updateParams')
            this.updateSearchLabel();
        },
        handleDropdownVisible(visible, type) {
            this[`${type}DropdownVisible`] = visible;
        },
        handleJobDropdown(command, type) {
            this.jobSearchForm[type] = command;
            this.addParams()
        },
        // removeLabel(param) {
        //     let search = this.jobSearchForm;
        //     if(this.searchLabels.hasOwnProperty(param.title)) {
        //         this.searchLabels[param.title].value = "";
        //     }
        //     switch(param.title) {
        //         case 'customerName':
        //         default:
        //             search[param.title] = "";
        //             this.addParams();
        //     }
        // },
        updateSearchLabel() {
            let list = [];
            // console.log(this.searchLabels);
            Object.values(this.searchLabels).forEach(label => {
                if(label.value) {
                    list.push({
                        title: label.name,
                        text: label.title,
                        value: label.value
                    })
                }
            });
            this.searchConditionList = list;
            // console.log(this.searchConditionList);
        },
        handleClearSearch() {
            let params = {
                status: "",
                // startDate: "",
                // endDate: "",
            };
            Object.assign(this.jobSearchForm, params);
            this.searchLabels = JSON.parse(JSON.stringify(defaultSearchLabels));
            
            this.addParams();
        },
    }
};
</script>

<style lang="scss" scope>
.public-job-search {
    position: relative;
    // margin-bottom: 20px;
    // background-color: #ffffff;
    // border-radius: 8px;
    .text-primary {
        color: $primary;
    }
    .job-search-form {
        position: relative;
        padding: 20px;
        padding-right: 0;
        background-color: #ffffff;
        border-radius: 0 0 8px 8px;
        margin-bottom: 20px;
        margin-right: 0;
        display: flex;
        flex-wrap: wrap;
        // justify-content: space-between;
        .el-form-item {
            width: 100%;
            margin-bottom: 20px;
            margin-right: 20px;
            &.input-inner-label {
                // width: 380px;
                width: 400px;
                .el-form-item__content {
                    position: relative;
                }
            }
            &.input-outer-label {
                .el-form-item__label {
                    text-align: left;
                    margin-right: 5px;
                    margin-bottom: 0;
                    width: 80px;
                    color: #666;
                    padding-right: 0;
                    font-size: 14px;
                    line-height: 26px;
                    font-weight: bold;
                }
                .el-form-item__content {
                    line-height: 26px;
                    padding-left: 85px;
                    // height: 26px;
                }
            }
        }
        .form-input-label {
            height: 40px;
            width: 80px;
            text-align: center;
            display: inline-block;
            position: absolute;
            z-index: 1;
            top: 1px;
            left: 1px;
            white-space: nowrap;
            background: #fff;
            line-height: 40px;
            font-size: 14px;
            color: #333;
            border-radius: 4px;
            &::after {
                content: "";
                display: inline-block;
                width: 1px;
                height: 24px;
                background: #ddd;
                position: absolute;
                right: 0;
                top: 9px;
            }
        }
        .isDisabled {
            background-color: #F5F7FA;
            color: #C0C4CC;
        }
        .search-select,
        .customer-select-input, 
        .search-input {
            width: 100%;
            height: 42px;
            .el-input__inner {
                height: 42px;
                line-height: 42px;
                padding-right: 20px;
                padding-left: 92px;
            }
            &.search-input-key {
                width: 100%;
                .el-input__inner {
                    padding-left: 20px;
                }
            }
            &.el-date-editor {
                padding: 0;
                padding-left: 92px;
                line-height: 42px;
                .el-icon-date {
                    display: none;
                }
                .el-range-separator {
                    line-height: 40px;
                }
                .el-range__close-icon {
                    margin-left: 15px;
                    line-height: 40px;
                }
            }
        }
        .el-radio-group.search-radio-group {
            // height: 26px;
            .el-radio {
                line-height: 25px;
                padding: 0 10px;
                margin-right: 5px;
                margin-bottom: 5px;
                border-radius: 2px;
                &:hover {
                    background: #e9e9e9;
                }
                &.is-checked {
                    background: $primary;
                    &:hover {
                        background: $primary;
                    }
                    .el-radio__label {
                        color: #fff;
                    }
                }
                .el-radio__input {
                    display: none;
                }
                .el-radio__label {
                    padding-left: 0;
                }
            }
        }
        .more-job-location-btn {
            display: inline-block;
            line-height: 26px;
            padding: 0 10px;
            margin-right: 5px;
            color: $primary;
            cursor: pointer;
            &:hover {
                background: #e9e9e9;
            }
        }
        .more-btn {
            position: absolute;
            bottom: 12px;
            left: 20px;
            .icon-font {
                font-size: 14px;
                color: $primary;
                cursor: pointer;
            }
            &:hover, &:focus {
                .icon-font {
                    color: #48CAAC;
                    text-decoration: underline;
                }
            }
            &:active {
                .icon-font {
                    color: #36B495;
                    text-decoration: underline;
                }
            }
        }
    }
    .job-search-bottom {
        // width: 1100px;
        width: 100%;
        min-width: 564px;
        height: 60px;
        line-height: 60px;
        background-color: #ffffff;
        border-radius: 8px 8px 0 0;
        padding: 0 20px;
        min-height: 32px;
        border-bottom: 1px solid #EEEEEE;
        .search-param-list {
            .search-param-item.el-tag {
                max-width: 815px;
                white-space: normal;
                line-height: 24px;
                height: auto;
                margin-right: 15px;
                margin-bottom: 8px;
            }
            .job-total {
                display: inline-block;
                margin-bottom: 8px;
            }
            .clear-search {
                display: inline-block;
                margin-bottom: 8px;
                margin-left: 20px;
                cursor: pointer;
            }
        }
        .job-search-select {
            display: inline-block;
            // line-height: 32px;
            line-height: 60px;
            .job-search-dropdown-title {
                color: #666;
            }
            .job-search-dropdown.el-dropdown {
                .el-dropdown-link {
                    // color: #b9b9b9;
                    color: #38BC9D;
                }
            }
            &.job-search-select-jobSource {
                margin-right: 28px;
            }
            &.job-search-select-orderBy {
                margin-left: 28px;
                float: right;
            }
        }
        .job-list-sum {
            float: right;
            // line-height: 32px;
            line-height: 60px;
        }
    }
    .team-select-box {
        width: 480px;
        height: 371px;
        margin-top: 5px;
        position: absolute;
        z-index: 10;
        background: #fff;
        box-shadow: 2px 2px 6px #999;
        .team-select.visible {
            .team-select-bd {
                margin-top: 5px;
            }
        }
        .button-list {
            margin-top: 333px;
            margin-left: 15px;
        } 
    }
    // .search-param-container {
    //     margin-bottom: 10px;
    //     background: #fff;
    //     .search-param-list {
    //         background: #f6f6f6;
    //         padding: 8px 20px 0;
    //         .search-param-item.el-tag {
    //             max-width: 815px;
    //             white-space: normal;
    //             line-height: 24px;
    //             height: auto;
    //             margin-right: 15px;
    //             margin-bottom: 8px;
    //         }
    //         .job-total {
    //             display: inline-block;
    //             margin-bottom: 8px;
    //         }
    //         .clear-search {
    //             display: inline-block;
    //             margin-bottom: 8px;
    //             margin-left: 20px;
    //             cursor: pointer;
    //         }
    //     }
    // }
}
</style>

<style lang="scss">
    .job-search-dropdown-menu.el-dropdown-menu {
        margin-top: 0;
        padding: 0;
        .popper__arrow {
            display: none;
        }
        .el-dropdown-menu__item.active {
            background-color: #ebf8f5;
            color: $primary;
        }
    }
    .city-select-dialog.el-dialog {
        .el-dialog__header {
            display: none;
        }
        .el-dialog__body {
            position: relative;
        }
        .city-select-dialog-close {
            position: absolute;
            right: 20px;
            top: 16px;
            cursor: pointer;
            font-weight: bold;
            font-size: 18px;
            color: #b9b9b9;
            &:hover {
                color: #cf0224;
            }
        }
    }
</style>
