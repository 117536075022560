var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "audit-container" }, [
    _c("div", { staticClass: "top-wrap" }, [
      _c(
        "div",
        { staticClass: "job-search-cell" },
        [
          _c("div", { staticClass: "item-label" }, [_vm._v("职位")]),
          _c("el-input", {
            staticClass: "job-search-input",
            attrs: { clearable: "", placeholder: "搜索职位名称（按回车搜索）" },
            on: {
              change: _vm.handlesearchJobChange,
              clear: _vm.handlesearchJobChange,
            },
            model: {
              value: _vm.searchJob,
              callback: function ($$v) {
                _vm.searchJob = $$v
              },
              expression: "searchJob",
            },
          }),
        ],
        1
      ),
    ]),
    _c(
      "div",
      { staticClass: "main" },
      [
        _c("job-list", {
          ref: "publicJobList",
          attrs: {
            jobList: _vm.newJobList,
            listLoading: _vm.loading,
            params: _vm.params,
          },
          on: { refresh: _vm.getJobList },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }