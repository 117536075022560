<template>
    <div class="public-job-list">
        <job-search
            ref="publicJobSearch"
            :jobNumber="pager.total"
            @updateParams="search(false)"
        ></job-search>
        <div class="public-job-content">
            <div class="job-list-wrapper" v-loading="listLoading">
                <template v-if="jobList.length">
                    <job-item
                        ref="publicJobItem"
                        v-for="(job, index) in jobList"
                        :key="index"
                        :job="job"
                        @updateParams="search(false)"
                    ></job-item>
                </template>
                <div class="empty" v-else>
                    <span class="empty-img"></span>
                    <p class="empty-data">暂无数据</p>
                </div>
                <el-pagination
                    :current-page="pager.current"
                    :page-sizes="[10, 30, 50]"
                    :page-size="pager.take"
                    class="job-list-pagination"
                    layout="prev, pager, next, slot, total, sizes"
                    :total="pager.total"
                    v-if="jobList.length > 0"
                    @size-change="handleSizeChange"
                    @current-change="handleCurrentChange"
                >
                    <span class="pagination-text">
                        <span>
                            前往
                            <el-input
                                v-model="pagerJump"
                                @keyup.enter.native="handlePagerJump(pagerJump)"
                            ></el-input>
                            页
                        </span>
                        <span @click="handlePagerJump(pagerJump)">
                            跳转
                        </span>
                    </span>
                </el-pagination>
            </div>
        </div>

        <div class="lbd-sign"></div>

    </div>
</template>

<script>
import JobSearch from '../component/job-search.vue';
import JobItem from '../component/job-item.vue';

export default {
    props: {
        jobList: {
            type: Array,
            default: () => {
                return [];
            }
        },
        listLoading: {
            type: Boolean,
            default: false
        },
        params: {
            type: Object,
            default: () => {
                return {}
            }
        },
    },
    components: {
        JobSearch,
        JobItem,
    },
    data() {
        return {
            pager: {
                current: 1,
                take: 10,
                total: 0,
            },
            pagerJump: 0,
        };
    },
    computed: {
        
    },
    mounted() {
        if(this.$route.query.startDate != null) {
            this.$refs.publicJobSearch.changeCreatedTime([this.$route.query.startDate, this.$route.query.endDate]);
        } else {
            this.search();
        }

        let thisMonth = new Date().getMonth();
        let lastMonthDay = new Date().setMonth(thisMonth - 1);
    },
    methods: {
        search(slient) {
            let params = {};
            if(this.$refs.publicJobSearch) {
                params = Object.assign({}, this.$refs.publicJobSearch.jobSearchForm);
            }
            if(!slient) {
                this.pager.current = 1;
            }
            params.start = (this.pager.current - 1) * this.pager.take;
            params.take = this.pager.take;
            this.$emit('refresh', params, slient);
        },
        handleSizeChange(val) {
            this.pager.take = val;
            this.pager.current = 1;
            this.search(true);
        },
        handleCurrentChange(current) {
            this.pager.current = current;
            this.search(true);
        },
        handlePagerJump(pagerJump) {
            pagerJump = Number.parseInt(pagerJump);
            if (pagerJump > 0 && pagerJump <= Math.ceil(this.pager.total / this.pager.take)) {
                this.handleCurrentChange(pagerJump);
            }
        },
    }
};
</script>

<style lang="scss" scope>
    .public-job-list {
        width: 100%;
        .public-job-content {
            display: flex;
            justify-content: space-between;
            position: relative;
            min-height: calc(100vh - 184px);
            .job-list-wrapper {
                width: 100%;
                min-width: 564px;
                min-height: 200px;
                padding: 0 20px;
                background-color: #fff;
                border-radius: 0 0 8px 8px;

                .empty {
                    min-height: 200px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    flex-direction: column;
                    .empty-img {
                        display: inline-block;
                        background: url('~@src/assets/images/home/icon_no_data.svg') no-repeat;
                        background-size: contain;
                        width: 170px;
                        height: 160px;
                    }
                    .empty-data {
                        font-size: 14px;
                        margin-bottom: 0px;
                    }
                }
                .job-list-pagination.el-pagination {
                    margin: 40px 0 20px 0;
                    padding: 0;
                    font-weight: normal;
                    .btn-next, .btn-prev {
                        height: 38px;
                        border: 1px solid;
                    }
                    .btn-prev {
                        border-right: none;
                        border-radius: 4px 0 0 4px;
                    }
                    .btn-next {
                        border-left: none;
                        border-radius: 0 4px 4px 0;
                    }
                    .el-pagination__sizes .el-input{
                        width: 87px;
                        .el-input__inner {
                            color: #666;
                            .el-select__caret{
                                color: #999;
                            }
                        }
                    }
                    .el-pager {
                        border-top: 1px solid;
                        border-bottom: 1px solid;
                        li {
                            min-width: 36px;
                            height: 36px;
                            line-height: 36px;
                            font-size: 14px;
                        }
                    }
                    .btn-next, .btn-prev, .el-pager {
                        border-color: #DDDDDD;
                    }
                    .el-input__inner, .el-pagination__total, .el-pagination__jump {
                        height: 38px;
                        line-height: 38px;
                        font-size: 14px;
                    }
                    .pagination-text{
                        color: #999;
                        span{
                            height: 38px;
                            line-height: 38px;
                            font-size: 14px;
                            .el-input{
                                width: 48px;
                                margin: 0 5px;
                                &__inner{
                                    height: 38px;
                                    line-height: 38px;
                                    padding: 0 5px;
                                }
                            }
                            &:nth-of-type(2){
                                margin-left: 5px;
                                color: $primary;
                                cursor: pointer;
                            }
                        }
                    }
                    .el-pagination__total,
                    .el-pagination__sizes,
                    .pagination-text {
                        float: right;
                    }
                }
            }
        }

        .lbd-sign {
            padding: 20px;
            &:after {
                content: '© 2024 liebide.com';
                margin-left: 50%;
                text-align: center;
                font-size: 14px;
                color: #999;
            }
        }
    }
</style>